import '/assets/styles/partials/pagination.scss';

import config from '/config';

import { useMemo } from 'react'

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';

// Partials
import Btn from '/views/partials/btn'
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Pagination
const Pagination = ({ data, onPaginate, className }) => {
	const { mobile } = useBreakpoints();

	const content = useMemo(() => {
		let newContent = [];
		if(data) {

			let start = data.page - (mobile ? config.listingMobilePaginationWidth : config.listingPaginationWidth) - 1;
			let end = data.page + (mobile ? config.listingMobilePaginationWidth : config.listingPaginationWidth) - 1;

			if (start < 0) {
				start = 0;
			}
			if (end > (data.page_count - 1)) {
				end = data.page_count - 1;
			}

			if (start > 0) {
				newContent.push('1');
				newContent.push(false);
			}

			for (let k = start; k <= end; k++) {
				const pg = k + 1;
				newContent.push(pg.toString());
			}

			if (end < data.page_count - 1) {
				newContent.push(false);
				newContent.push(data.page_count.toString());
			}	
		}
		return newContent;
	}, [data, mobile])

	if(data) {
		return (
			<nav className={'pagination ' + (className ? className : '')}>
				<Btn
					className="pagination-navbtn outline small prev"
					disabled={data.page === 1}
					onClick={() => { onPaginate(data.page - 1) }}>
					<Icon name="arrow-left" /> <span>Önceki{!mobile ? ' Sayfa' : ''}</span>
				</Btn>
				<div className="pagination-nums">
					{mobile ?
						<>
							<label className="nums-display" htmlFor="pagination-mobileselector">
								{data.page} / {data.page_count}
								
								<select
									className="display-select"
									value={data.page.toString()}
									onChange={(e) => { onPaginate(e.target.value) }}
									id="pagination-mobileselector">
									{content.map((page, nth) => {
										if(page) { return (
											<option value={page} key={nth}>{page}</option>
										)}
										else { return (
											<option value="" key={nth} disabled>...</option>
										)}
									})}
								</select>
							</label>
						</>
						:
						<>
							{content.map((page, nth) => (
								<PageBtn
									page={page}
									key={nth}
									curPage={data.page}
									onClick={onPaginate} />
							))}
						</>
					}
				</div>
				<Btn
					className="pagination-navbtn outline small next"
					disabled={data.page === data.page_count}
					onClick={() => { onPaginate(data.page + 1) }}>
					<span>Sonraki{!mobile ? ' Sayfa' : ''}</span> <Icon name="arrow-right" />
				</Btn>
			</nav>
		)
	}
	else { return false; }
}

const PageBtn = ({ page, curPage, onClick }) => {
	const active = curPage.toString() === page;
	return (
		<>
			{page ?
				<button
					type="button"
					disabled={active}
					onClick={() => { onClick(page); }}
					className={'pagination-button' + (active ? ' active' : '')}>
					{page}
				</button>
			:
				<span className="pagination-seperator">...</span>
			}
		</>
	)
}

export default Pagination;